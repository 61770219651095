import { ref, watch } from "@vue/composition-api";

// Notification
import contestRepository from "@/abstraction/repository/contestRepository";

const repository = new contestRepository();
export default function usemoodList() {
    const refplaylistListTable = ref(null);
    const contests = ref([]);
    const perPage = ref(10);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const currentPage = ref(1);
    const searchQuery = ref("");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const pagination = ref({});
    const secondContestPostId = ref();
    const candidatePagination = ref({});
    const candidates = ref([]);
    const refetchData = () => {
        refplaylistListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    // Table Handlers
    const tableColumns = [{ key: "name", sortable: true }, { key: "stages", sortable: true }, { key: "groups", sortable: true }, { key: "status", sortable: true }, { key: "candidates_select", sortable: true }, { key: "actions" }];
    const stageTableColumns = [{ key: "name", sortable: true }, { key: "created_at", sortable: true }, { key: "status", sortable: true }, { key: "publish_status", sortable: true }, { key: "actions" }];
    const groupTableColumns = [{ key: "name", sortable: true }, { key: "created_at", sortable: true }, { key: "is_active", sortable: true }, { key: "is_published", sortable: true }, { key: "actions" }];

    const storeContest = async (data) => {
        try {
            return await repository.store(data);
        } catch (e) {
            return false;
        }
    };
    const storeStageContest = async (data) => {
        try {
            return await repository.storeStageContest(data);
        } catch (e) {
            return false;
        }
    };
    const storeGroupContest = async (data) => {
        try {
            return await repository.storeGroupContest(data);
        } catch (e) {
            return false;
        }
    };
    const showContestGroup = async (id) => {
        try {
            return await repository.showContestGroup(id);
        } catch (e) {
            return false;
        }
    };
    const showParticipant = async (id) => {
        try {
            return await repository.showParticipant(id);
        } catch (e) {
            return false;
        }
    };

    const showContest = async (id) => {
        try {
            return await repository.show(id);
        } catch (e) {
            return false;
        }
    };
    // const contestCandidatesList = async (id) => {
    //     try {
    //         return await repository.contestCandidatesList(id);
    //     } catch (e) {
    //         return false;
    //     }
    // };
    const removeCandidate = async (id) => {
        try {
            return await repository.removeCandidate(id);
        } catch (e) {
            return false;
        }
    };
    const showStageContest = async (id) => {
        try {
            return await repository.showStageContest(id);
        } catch (e) {
            return false;
        }
    };

    const updateContest = async (id, data) => {
        try {
            return await repository.update(id, data);
        } catch (e) {
            return false;
        }
    };
    const updateContestGroup = async (id, data) => {
        try {
            return await repository.updateContestGroup(id, data);
        } catch (e) {
            return false;
        }
    };
    const updateStageContest = async (id, data) => {
        try {
            return await repository.updateStageContest(id, data);
        } catch (e) {
            return false;
        }
    };
    const storeCandidates = async (data, contestId) => {
        try {
            return await repository.storeCandidates(data, contestId);
        } catch (e) {
            return false;
        }
    };
    const fetchContest = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const contestsList = await repository.index({ filters, pagination: paginate });
        contests.value = contestsList.data;
        pagination.value = contestsList.pagination;
        return contests;
    };
    const fetchStageContest = async (id) => {
        console.log(id);
        const contestsList = await repository.fetchStageContest(id);
        contests.value = contestsList.data;
        pagination.value = contestsList.pagination;
        return contests;
    };
    const fetchGroupContest = async (id) => {
        console.log(id);
        const contestsList = await repository.fetchGroupContest(id);
        contests.value = contestsList.data;
        pagination.value = contestsList.pagination;
        return contests;
    };
    const contestCandidatesList = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        // console.log(secondContestPostId.value);
        const candidateList = await repository.contestCandidatesList({ filters, pagination: paginate }, secondContestPostId.value);
        candidates.value = candidateList.data;
        candidatePagination.value = candidateList.pagination;
        return candidates;
    };
    const deleteContest = async (id) => {
        try {
            const response = await repository.delete(id);
            // pagination.value.itemsLength--;
            // const index = contests.value.findIndex((x) => x.id === id);
            // contests.value.splice(index, 1);
            fetchContest();
            return response;
        } catch (e) {
            return false;
        }
    };

    const changePagePlaylist = async (page) => {
        const response = await fetchContest({ pagination: page });
        return response;
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        contests,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        storeContest,
        showContest,
        updateContest,
        fetchContest,
        deleteContest,
        storeStageContest,
        fetchStageContest,
        showStageContest,
        updateStageContest,
        storeGroupContest,
        fetchGroupContest,
        stageTableColumns,
        groupTableColumns,
        storeCandidates,
        contestCandidatesList,
        removeCandidate,
        showContestGroup,
        updateContestGroup,
        showParticipant,
        secondContestPostId,
        candidatePagination,
        candidates,
    };
}
