import axios from "axios";
import url from "../url";
// import { getArray, setQuery, getJson, setData } from "../resources/genresResource";
import { getArray, setQuery, getJson, setData, getCandidateArray } from "../resources/contestPostResource";
export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }

    async index(data) {
        // const params = setQuery(data)
        const response = await axios.get(url("indexContests"));
        if (response.status === 200) {
            return response.data;
        }
    }
    async fetchStageContest(id) {
        const response = await axios.get(url("indexStageContests", { stageId: id }));
        if (response.status === 200) {
            return response.data;
        }
    }
    async fetchGroupContest(id) {
        const response = await axios.get(url("fetchGroupContest", { stageId: id }));
        if (response.status === 200) {
            return response.data;
        }
    }
    async showContestGroup(id) {
        const response = await axios.get(url("showContestGroup", { groupId: id }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async show(ShowID) {
        const response = await axios.get(url("showContest", { id: ShowID }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async showStageSong(songId) {
        const response = await axios.get(url("showStageSong", { songId: songId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async showStageContest(ShowID) {
        const response = await axios.get(url("showStageContest", { id: ShowID }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    // async contestCandidatesList(data, contestId) {
    //     console.log(contestId);
    //     console.log(data);
    //     const params = setQuery(contestId);
    //     console.log(params);
    // const response = await axios.get(url("contestCandidatesList", { contestId: contestId }), { params });
    // if (response.status === 200) {
    //     return getArray(response.data);
    // }
    // }
    async contestCandidatesList(data, contestId) {
        const params = setQuery(data);
        const response = await axios.get(url("contestCandidatesList", { contestId: contestId }), { params });
        if (response.status === 200) {
            return getCandidateArray(response.data);
        }
    }
    async store(data) {
        const response = await axios.post(url("storeContest"), data);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }
    async storeSong(data) {
        // const json = setData(data)
        const response = await axios.post(url("storeSong"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async storeCandidates(data, contestId) {
        const response = await axios.put(url("storeCandidates", { contestId: contestId }), data);
        if (response.status === 200) {
            return response.data;
        }
    }
    async publishAParticipantVideo(data, id) {
        const response = await axios.put(url("publishAParticipantVideo", { videoId: id }), data);
        if (response.status === 200) {
            return response.data;
        }
    }
    async showParticipant(id) {
        const response = await axios.get(url("showParticipant", { participantId: id }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async storeStageContest(data) {
        const response = await axios.post(url("storeStageContest"), data);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }
    async storeGroupContest(data) {
        const response = await axios.post(url("storeGroupContest"), data);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }

    async update(id, data) {
        // const json = setData(data)
        const response = await axios.put(url("updateContest", { id: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async updateStageMusics(id, data) {
        // const json = setData(data)
        const response = await axios.put(url("updateStageMusics", { id: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async updateStageContest(id, data) {
        // const json = setData(data)
        const response = await axios.put(url("updateStageContest", { id: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async updateContestGroup(id, data) {
        // const json = setData(data)
        const response = await axios.put(url("updateContestGroup", { id: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async updateSort(playlistId) {
        const response = await axios.put(url("updateSortMoods", { mood: playlistId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async delete(id) {
        const response = await axios.delete(url("deleteContest", { id: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async removeCandidate(id) {
        const response = await axios.delete(url("removeCandidate", { id: id }));
        if (response.status === 200) {
            return true;
        }
    }

    async attach(moodId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("attachMoods", { mood: moodId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async detach(moodId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("detachMoods", { mood: moodId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }
}
